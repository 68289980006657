import React, { useEffect } from "react"
import { PageProps } from "gatsby"

const AppStoreRedirect: React.FC<PageProps> = ({ location }) => {
  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.test.dieselbank"
      return
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://apps.apple.com/br/app/dieselbank-para-caminhoneiros/id1516013322"
      return
    }

    window.location.href = "/"
  })

  return <></>
}

export default AppStoreRedirect
